import { Checkbox, OptionObject } from '@components'
import cn from 'classnames'
import { useState } from 'react'

type MultiSelectProps = {
  /** Label to place on the left or top of the input */
  label?: string
  labelPosition?: 'LEFT' | 'TOP'
  /**
   * Set the input as required and adds an `*` to the label.
   * @default true
   * */
  required?: boolean
  /** Handle onChange events */
  onChange: (values: string[]) => void
  /** Accepts array of strings or an object of types */
  /** TODO: Possibly have a generic type with a getter */
  options: string[] | OptionObject[]
  /** List of values that are currently selected */
  selected: string[]
  /** Custom className styles */
  className?: string
}

/** Controlled multi select dropdown
 * TODO: Use useClickOutside hook to handle cancellation and use
 * e.stopPropagation to the checkbox from affecting the parent onClick to close
 * the dropdown
 * TODO: Possibly render a hidden select and match the values given
 */
export function MultiSelect({
  label,
  labelPosition = 'LEFT',
  required = true,
  onChange,
  options: _options = [],
  selected,
  className,
}: MultiSelectProps) {
  const optionType = typeof _options[0] === 'string' ? 'string' : 'object'
  const options =
    optionType === 'string'
      ? ((_options as string[]).map((value) => ({ value, name: value })) as OptionObject[])
      : (_options as OptionObject[])

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <label
      className={cn(
        'relative flex gap-2 items-center cursor-pointer',
        {
          'flex-col': labelPosition === 'TOP',
        },
        className
      )}
      onClick={() => setIsMenuOpen((prevValue) => !prevValue)}
    >
      {label && (
        <div
          className={cn('flex-shrink-0', {
            'self-start': labelPosition === 'TOP',
          })}
        >
          {/* Add '*' to all labelled inputs that are required */}
          {label && `${label}${required ? '*' : ''}`}
        </div>
      )}
      {/* Dropdown Input */}
      <div
        className={cn('select flex-grow z-50 w-0', {
          'w-full': labelPosition === 'TOP',
          'border-pink': isMenuOpen,
        })}
      >
        {/* Arrow */}
        <div
          className={cn('rounded-full absolute right-2 align-middle transition-all', {
            'transform rotate-180': isMenuOpen,
          })}
          style={{
            height: 16,
            width: 16,
            backgroundColor: '#C7327C59',
            top: 'calc(50% - 8px)',
            backgroundImage: "url('/arrow.svg')",
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '55% 50%',
          }}
        ></div>
        <div className='overflow-ellipsis overflow-hidden whitespace-nowrap'>
          {/* Render all selected options */}
          {options
            .filter(({ value }) => selected.includes(value))
            .map(({ value, name }) => name || value)
            .join(', ')}
        </div>
        {/* Dropdown menu */}
        {isMenuOpen && (
          <div
            className='absolute left-0 right-0 bottom-1 rounded-2xl shadow-2xl p-3 bg-white transition flex flex-col gap-1 overflow-auto'
            style={{
              // 42px + 8px
              top: 'calc(42px + 8px)',
              height: 'max-content',
              borderColor: '#edecec',
              maxHeight: 200,
            }}
          >
            {options.map(({ value, name }) => (
              <Checkbox
                key={value}
                selected={selected.includes(value)}
                value={value}
                name={name}
                onChange={(isSelected) =>
                  onChange(isSelected ? [...selected, value] : selected.filter((_value) => _value != value))
                }
              />
            ))}
          </div>
        )}
      </div>
    </label>
  )
}
