import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'

export function Nav() {
  const { asPath: currentPath } = useRouter()

  return (
    <nav className='container md:flex flex-none items-center justify-between md:text-sm text-xs mt-10'>
      <Link href='/'>
        <a className='block text-right'>
          <img src='/logo.png' alt='logo' width='220' className='inline' />
        </a>
      </Link>
      <div className='flex gap-6 items-center lg:pl-20 pb-2 border-b border-black border-solid font-nav font-medium text-grey lowercase'>
        <Link href='/events'>
          <a
            className={classNames('transition-all hover:text-pink', {
              'text-pink': currentPath === '/events',
            })}
          >
            events & webinars
          </a>
        </Link>
        <Link href='/api/login'>
          <a className='transition-all hover:text-pink'>log in</a>
        </Link>
        <Link href='/api/login'>
          <a className='transition-all hover:text-pink'>sign up</a>
        </Link>
        {/* TODO: Ignore the query here, just to remember */}
        {/* TODO: Not sure how to get this working */}
        {/* <Link href={{ pathname: "/api/signup", query: { prompt: "signup" } }}>
          <a className="transition-all hover:text-pink">sign up</a>
        </Link> */}
      </div>
    </nav>
  )
}
