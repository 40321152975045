import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'

export function Sidebar() {
  const { pathname } = useRouter()

  return (
    <aside className='sidebar flex-shrink-0'>
      <Link href='/home'>
        <a
          className={classNames('flex sidebar-link', {
            'sidebar-link-active': pathname.includes('/home'),
          })}
        >
          {pathname.includes('/home') ? (
            <img src='/home-active.png' alt='home' width='24px' height='24px' />
          ) : (
            <img src='/home-white.png' alt='home' width='24px' height='24px' />
          )}
          <span className='ml-3'>home</span>
        </a>
      </Link>
      <Link href='/connections'>
        <a
          className={classNames('flex sidebar-link', {
            'sidebar-link-active': pathname.includes('/connections'),
          })}
        >
          {pathname.includes('/connections') ? (
            <img src='/connection-active.png' alt='connection' width='24px' height='24px' />
          ) : (
            <img src='/connection-white.png' alt='connection' width='24px' height='24px' />
          )}
          <span className='ml-3'>connections</span>
        </a>
      </Link>
      <Link href='/profiles'>
        <a
          className={classNames('flex sidebar-link', {
            'sidebar-link-active': pathname.includes('/profile'),
          })}
        >
          {pathname.includes('/profiles') ? (
            <img src='/profile-active.png' alt='profile' width='24px' height='24px' />
          ) : (
            <img src='/profile-white.png' alt='profile' width='24px' height='24px' />
          )}
          <span className='ml-3'>profile</span>
        </a>
      </Link>
      <Link href='/api/logout'>
        <a className='ml-3 text-white mt-auto'>
          <span>logout</span>
        </a>
      </Link>
    </aside>
  )
}
