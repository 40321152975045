import Link from "next/link";

export function Header() {
  return (
    <header className="app-header">
      <Link href="/">
        <a className="inline-block" style={{ width: 150 }}>
          <img src="/logo.png" alt="logo" />
        </a>
      </Link>
    </header>
  );
}
