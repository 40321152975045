import { Post as PostType } from '.prisma/client'
import { ExpertiseSidebar, Post, TextButton } from '@components'
import { User } from '@prisma/client'
import axios from 'axios'
import cn, { Argument } from 'classnames'
import React, { useState } from 'react'
import { QueryFunction, QueryKey, useMutation, useQuery, useQueryClient } from 'react-query'
import { EditFormType } from './Post'

type FeedProps = {
  /**
   * The authenticated user. This is used to determine which posts to show
   * a "view profile" button on.
   */
  loggedInUser: User
  // Optional title for the Feed (this is the first tab)
  title?: string
  /** Show post only for userId */
  userId?: string
  /**
   * Show Feed as a readOnly feed. When false, edit and delete capabilities
   * are enabled
   * @default true
   */
  readOnly?: boolean
  className?: Argument
}

const getPosts: QueryFunction<(PostType & { User: User })[], [QueryKey, { userId: string }?]> = async (context) => {
  const { userId } = context.queryKey?.[1] ?? {}
  return await axios.get(`/api/posts${userId ? '?userId=' + userId : ''}`).then((res) => res.data)
}

export function Feed({ userId, readOnly = true, ...props }: FeedProps) {
  const queryClient = useQueryClient()
  const { isLoading, error, data: posts } = useQuery(['posts', { userId }], getPosts)
  const { mutate: onDelete } = useMutation((postId: string) => axios.delete(`/api/posts/${postId}`), {
    mutationKey: 'deletePost',
    onSuccess: () => {
      queryClient.invalidateQueries('posts')
    },
  })
  const { mutate: onEdit } = useMutation(
    ({ id: postId, ...form }: EditFormType) => axios.put(`/api/posts/${postId}`, form),
    {
      mutationKey: 'editPost',
      onSuccess: () => {
        queryClient.invalidateQueries('posts')
      },
    }
  )

  if (error) console.error(error)

  return (
    !isLoading &&
    !error && (
      <FeedBase
        {...props}
        posts={posts}
        // When in readOnly mode and filtering via userID
        {...(userId && { hideProfileLink: true })}
        // When readOnly is set to false, enable the deletion of posts
        // TODO: Maybe we can have a more robust solution for this
        {...(!readOnly && { onDelete })}
        {...(!readOnly && { onEdit })}
      />
    )
  )
}

type FeedBaseProps = {
  loggedInUser: FeedProps['loggedInUser']
  className?: FeedProps['className']
  posts: (PostType & { User: User })[]
  // Optional title for the Feed (this is the first tab)
  title?: string
  hideProfileLink?: boolean
  /** Enables deletion of posts */
  onDelete?: (postId: string) => void
  /** Enables editions of posts */
  onEdit?: (form: EditFormType) => void
}

export function FeedBase({ className, loggedInUser, posts, title, hideProfileLink, onDelete, onEdit }: FeedBaseProps) {
  const [selectedExpertise, setSelectedExpertise] = useState<string[]>([])
  const [selectedTab, setSelectedTab] = useState<string>('post') // TODO: Add tab support

  // Build a list of expertise fields bases on the posts returned
  const categories = [...new Set(posts.flatMap((p) => p.categories))]

  return (
    <div className={cn(className, 'flex')} id='feed'>
      {/* Expertise */}
      <ExpertiseSidebar
        className='mt-6'
        onClick={setSelectedExpertise}
        options={categories.map((e) => ({
          value: e,
        }))}
        selected={selectedExpertise}
      />
      {/* Feed */}
      <div className='flex-grow flex flex-col'>
        {/* Tabs */}
        <div className='border-gray border-b'>
          <TextButton
            className={cn({
              'text-pink': selectedTab === 'post',
            })}
          >
            {title || 'post'}
          </TextButton>
        </div>
        {/* Posts */}
        <div className='flex-grow pt-5 pl-5 flex flex-col gap-5 mb-5'>
          {posts.length ? (
            posts
              .filter((p) =>
                !selectedExpertise.length ? true : p.categories.some((e) => selectedExpertise.includes(e))
              )
              .map((post) => (
                <Post
                  loggedInUser={loggedInUser}
                  post={post}
                  user={post.User}
                  key={post.id}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  hideProfileLink={hideProfileLink}
                />
              ))
          ) : (
            <p className='text-center font-extrabold'>No Posts</p>
          )}
        </div>
      </div>
    </div>
  )
}
