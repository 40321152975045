import Link from "next/link";

export function Footer() {
  return (
    <footer className="bg-blue-dark py-5">
      <div className="container flex justify-between text-white">
        <div className="w-1/4">
          <h2 className="text-white font-bold">Alchemy</h2>
          <p className="text-xs">
            Collaborative problem solving for the public sector
          </p>
        </div>
        <div className="uppercase text-sm w-1/4 text-right flex flex-col justify-evenly">
          {/* FIXME: Enable when login is working */}
          {/* <Link href="/api/login">
            <a className="transition-all hover:text-pink">Sign in</a>
          </Link>
          <Link href="/api/login">
            <a className="transition-all hover:text-pink">Sign up</a>
          </Link> */}
          {/* FIXME: Enable when contact email exists */}
          {/* <Link href="/">
            <a className="transition-all hover:text-pink">Contact</a>
          </Link> */}
          <Link href="https://iese.org.uk">
            <a
              className="lowercase flex justify-end items-baseline"
              target="_blank"
            >
              <span className="transition-all hover:text-pink">powered by</span>
              <img src="/iese.png" alt="iese" className="h-5 inline-block" />
            </a>
          </Link>
        </div>
      </div>
    </footer>
  );
}
