import { useToggleState } from '@utils'
import Image from 'next/image'
import { useEffect, useState } from 'react'

type InputProps = {
  label?: string
  labelPosition?: 'TOP' | 'LEFT'
  required?: boolean
  value: string
  onChange: (value: string) => void
}

/** Labelled input component */
export function Input({ label, labelPosition = 'TOP', required = false, value: _value, onChange }: InputProps) {
  // Defaulting null or undefined values to "" to keep input controlled
  const value = _value ?? ''

  return (
    <label className='text-14 text-gray font-medium mt-0'>
      {labelPosition === 'LEFT' ? (
        <div className='flex items-center gap-1'>
          {/* Add '*' to all labelled inputs that are required */}
          {label && `${label}${required ? '*' : ''}`}
          <input
            style={{ marginTop: 0 }}
            type='text'
            required={required}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            name={label}
          />
        </div>
      ) : (
        <>
          {label && (
            <div className='mb-1'>
              {/* Add '*' to all labelled inputs that are required */}
              {`${label}${required ? '*' : ''}`}
            </div>
          )}
          <input
            style={{ marginTop: 0 }}
            type='text'
            required={required}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            name={label}
          />
        </>
      )}
    </label>
  )
}

type TextFieldProps = {
  /** Render children when readOnly is false */
  children: JSX.Element
  /**
   * Disable edit-ability of the text
   * @deprecated Use `readOnly` instead
   */
  disabled?: boolean
  /**
   * Show form element as a readOnly value. User cannot change the value
   * @default false
   * */
  readOnly?: boolean
  /** Handler when clicking on the checkmark to commit changes */
  onChange: (value: string) => void
} & InputProps

/**
 * Renders a styled input field with edit mode toggle and the ability to save
 * changes or cancel changes. */
export function TextField({
  children,
  disabled = false,
  readOnly = (disabled = false),
  onChange,
  ...inputProps
}: TextFieldProps) {
  // TODO: xState might be helpful here
  const [isEditMode, toggleEditMode] = useToggleState(false)
  // Holding local state since TextField can have edited content and must be
  // able to render the original content
  const [inputValue, setInputValue] = useState(inputProps.value)

  // Update the input value to it's original value when going into edit mode
  useEffect(() => {
    if (isEditMode === true) {
      setInputValue(inputProps.value)
    }
  }, [isEditMode])

  useEffect(() => {
    // Reset the edit state when new values are given
    toggleEditMode(false)
  }, [inputProps.value])

  return readOnly ? (
    children
  ) : (
    <>
      {!isEditMode ? (
        <div className='flex gap-1 items-center'>
          {children}
          <img className='cursor-pointer' onClick={() => toggleEditMode()} src='/pencil.svg' alt='pencil' />
        </div>
      ) : (
        <div className='flex gap-1 items-center'>
          <Input {...inputProps} value={inputValue} onChange={setInputValue} />
          <div className='flex gap-1 items-center'>
            <Image
              src='/check.svg'
              alt='check'
              height='20px'
              width='20px'
              className='cursor-pointer'
              onClick={() => onChange(inputValue)}
            />
            <Image
              src='/cancel.svg'
              alt='cancel'
              height='17px'
              width='17px'
              className='cursor-pointer'
              onClick={() => toggleEditMode()}
            />
          </div>
        </div>
      )}
    </>
  )
}
