import cn from 'classnames'
import React, { CSSProperties, ReactNode } from 'react'

type ButtonProps = {
  children: ReactNode
  onClick?: () => void
  className?: string
  style?: CSSProperties
  disabled?: boolean
}

export function TextButton({ children, onClick, className, ...other }: ButtonProps) {
  // TODO: Possibly pass the event if needed
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => e.preventDefault && onClick && onClick()

  return (
    <button type='button' className={cn('text-btn', className)} onClick={handleClick} {...other}>
      {children}
    </button>
  )
}

/** Styled button to accept a child icon */
export function IconButton({ className, style, ...iconProps }: ButtonProps) {
  return <TextButton {...iconProps} className={cn('p-1 bg-white shadow-iconBtn', className)} style={style} />
}

/**
 * Small version of a button (could be an alternative style of a button)
 * TODO: Maybe we can make a default button to deal with prevent defaults
 */
export function PillButton({ children, onClick, disabled }: ButtonProps & { disabled?: boolean }) {
  return (
    <button
      className={cn('text-12 leading-none', {
        'bg-lightGrey': disabled,
      })}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

type IconProps = {
  name: keyof typeof Icons
}

export function Icon({ name }: IconProps) {
  return Icons[name]
}

const Icons = {
  plus: (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.54322 5.54371V0.521973H5.99975V5.54371H11.0215V6.00023H5.99975V11.022H5.54322V6.00023H0.521484V5.54371H5.54322Z'
        fill='#636463'
      />
    </svg>
  ),
  check: (
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24'>
      <path d='M20.303 4.846l.882.882-12.22 12.452-6.115-5.93.902-.902 5.303 5.028 11.248-11.53zm-.018-2.846l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285-3.715-3.715z' />
    </svg>
  ),
  cancel: (
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24'>
      <path d='M20.197 2.837l.867.867-8.21 8.291 8.308 8.202-.866.867-8.292-8.21-8.23 8.311-.84-.84 8.213-8.32-8.312-8.231.84-.84 8.319 8.212 8.203-8.309zm-.009-2.837l-8.212 8.318-8.31-8.204-3.666 3.667 8.321 8.24-8.207 8.313 3.667 3.666 8.237-8.318 8.285 8.204 3.697-3.698-8.315-8.209 8.201-8.282-3.698-3.697z' />
    </svg>
  ),
  delete: (
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24'>
      <path d='M9 19c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5-17v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712zm-3 4v16h-14v-16h-2v18h18v-18h-2z' />
    </svg>
  ),
  pencil: (
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24'>
      <path d='M8.071 21.586l-7.071 1.414 1.414-7.071 14.929-14.929 5.657 5.657-14.929 14.929zm-.493-.921l-4.243-4.243-1.06 5.303 5.303-1.06zm9.765-18.251l-13.3 13.301 4.242 4.242 13.301-13.3-4.243-4.243z' />
    </svg>
  ),
}
