import { Post as PostType } from '.prisma/client'
import { MultiSelect, Profile, Select } from '@components'
import { User } from '@prisma/client'
import { getCategories, offerings, useForm, useToggleState } from '@utils'
import React, { ReactNode, useState } from 'react'
import { useQuery } from 'react-query'
import { Icon, IconButton, PillButton, TextButton } from './Button'

export type PostProps = {
  loggedInUser: User
  post: PostType & { User: User }
  user: User
  /**
   * When given, a edit icon will appear beside the post which will open a
   * form edit modal.
   */
  onEdit?: (form: EditFormType) => void
  /** When given, a delete icon will appear beside the post */
  onDelete?: (id: string) => void
  /** Hides the profile link */
  hideProfileLink?: boolean
}

/** Post component which includes the author information */
export function Post({ loggedInUser, post, user, onEdit, onDelete, hideProfileLink }: PostProps) {
  const [isModalOpen, toggleModal] = useToggleState(false)
  const { title, createdAt: _createdAt, content, categories, ask } = post
  const createdAt = new Date(_createdAt)
  const formattedDate = new Intl.DateTimeFormat('default', {
    dateStyle: 'medium',
  }).format(createdAt)

  function handleDelete() {
    // Confirm with the user before deleting
    if (window.confirm('Are you sure you want to delete this post?')) {
      onDelete(post.id)
    }
  }

  return (
    <div className='flex flex-col gap-1'>
      {title && <p className='text-14 font-black'>{title}</p>}
      {/* Date and optional edit and delete button */}
      <div className='flex items-center justify-between'>
        <div className='text-blue text-10 font-la'>{formattedDate}</div>
        <div className='flex items-center gap-1'>
          {onEdit && (
            <>
              <IconButton onClick={toggleModal}>
                <Icon name='pencil' />
              </IconButton>
              {isModalOpen && (
                <EditModal
                  post={post}
                  onClose={toggleModal}
                  onEdit={(form) => {
                    onEdit(form)
                    toggleModal()
                  }}
                />
              )}
            </>
          )}
          {onDelete && (
            <IconButton onClick={handleDelete}>
              <Icon name='delete' />
            </IconButton>
          )}
        </div>
      </div>
      <p className='text-18 mb-1'>{content}</p>
      <Profile
        user={user}
        // Only show profileLink for non-logged in user
        showProfileLink={!hideProfileLink && loggedInUser.id !== user.id}
        // TODO: Add support for this afterwards
        // onConnect={() => console.log("Connecting to user")}
      />
      {/* Container with expertise and ask */}
      <div className='flex mt-2'>
        {/* Expertise */}
        <div className='flex-grow flex gap-2'>
          {categories.slice(0, 2).map((e) => (
            <PillButton disabled key={e}>
              {e}
            </PillButton>
          ))}
        </div>
        {/* Ask */}
        <div className='flex items-center font-normal text-blue text-12'>Seeking: {ask}</div>
      </div>
    </div>
  )
}

type EditModalProps = {
  post: PostProps['post']
  /** When clicking the overlay, close button or cancel */
  onClose: () => void
  /** When clicking save with a value form */
  onEdit: (form: EditFormType) => void
}

export type EditFormType = {
  id: string
  content: string
  categories: string[]
  ask: 'Consultation' | 'Peer Support'
}

function EditModal({ post, onEdit, onClose }: EditModalProps) {
  const form = useForm<EditFormType>({
    id: post.id,
    content: post.content,
    categories: post.categories,
    ask: post.ask as EditFormType['ask'],
  })
  const [error, setError] = useState(false)
  const { data: categories } = useQuery('categories', getCategories)

  function handleSubmit(e) {
    e.preventDefault()
    // Show an error when the form is not valid
    if (!form.valid) {
      setError(true)
      return
    }

    // Reset error state
    setError(false)

    // Otherwise, form is filled and will call API
    onEdit(form.value)
  }

  function removeCategory(category: string) {
    form.categories.set(form.categories.value.filter((_category) => _category !== category))
  }

  return (
    <Modal title='Edit Post' onClose={onClose}>
      <form className='border border-offWhite rounded-18 py-2 px-3 mb-3' onSubmit={handleSubmit}>
        <label className='font-medium'>
          Tell us about the problem that you are looking to solve
          <textarea
            className='mt-1 rounded-2xl shadow-xl border p-2 w-full'
            style={{ minHeight: 100 }}
            value={form.content.value}
            onChange={(e) => form.content.set(e.currentTarget.value)}
            required
          ></textarea>
        </label>
        {/* Categories */}
        <MultiSelect
          selected={form.categories.value}
          label='What type of problem is this?'
          options={categories?.map((value) => ({ value }))}
          onChange={form.categories.set}
          required
        />
        {!!categories?.length && (
          <div className='flex gap-1 mt-3'>
            {form.categories.value.map((category) => (
              <PillButton key={category} onClick={() => removeCategory(category)}>
                {category}
              </PillButton>
            ))}
          </div>
        )}
        <Select
          label='What type of help are you looking for?'
          options={offerings}
          selected={form.ask.value}
          onChange={form.ask.set}
        />
        <div className='flex justify-end mt-3 gap-3 items-center'>
          {error && <span className='text-pink text-16 text-center'>Opps, you have missing a field!</span>}
          <div className='flex gap-1'>
            <TextButton onClick={onClose}>Cancel</TextButton>
            <button type='submit'>Save</button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

/** Modal component */
function Modal({ children, onClose, title }: { children: ReactNode; onClose: () => void; title: string }) {
  return (
    // Overlay
    <div
      className='fixed inset-0 flex items-center justify-center z-10'
      style={{ backgroundColor: 'rgba(0,0,0,.5)' }}
      onClick={() => onClose()}
    >
      {/* Modal */}
      <div
        className='shadow-2xl border-2 border-gray p-3 rounded-18 bg-white'
        style={{ maxWidth: '600px' }}
        // Stopping any clicks within from triggering an onClose
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header */}
        <header className='flex items-center justify-between mb-2'>
          <h2 className='text-pink mb-0' style={{ textTransform: 'none' }}>
            {title}
          </h2>
          <IconButton onClick={onClose}>
            <Icon name='cancel' />
          </IconButton>
        </header>
        {/* Content + Footer */}
        {children}
      </div>
    </div>
  )
}
