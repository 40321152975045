import { User } from '@prisma/client'
import axios from 'axios'

/** All queries to interact with the API */

/** Utility to get the global expertise options*/
export function getExpertise() {
  return axios.get<string[]>('/api/categories').then((res) => res.data)
}

/** Returns categories or interests */
export function getCategories() {
  return getExpertise()
}

export async function getConnections() {
  return axios.get<User[]>('/api/connections').then((res) => res.data)
}

export async function getSuggestedConnections() {
  return axios.get('/api/suggestedConnections').then((res) => res.data)
}

export async function getConnectionRequests() {
  return axios.get('/api/connectionRequests').then((res) => res.data)
}

/** The authenticated user is accepting the friend request of `userId` */
export function acceptFriendRequest(userId: string) {
  return axios.post('/api/connections', { userId })
}

/** The authenticated user is declining the friend request of `userId` */
export function declineFriendRequest(userId: string) {
  return axios.delete(`/api/connectionRequests/${userId}`)
}

export async function createConnectionRequest(userId: string) {
  return axios.post('/api/connectionRequests', { userId }).then(({ data }) => data)
}

/* Chemistry Meeting */
/**
 * Determines if there is an existing Chemistry Meeting between the
 * authenticated user and the userId.
 */
export async function getChemistryMeetingsUrl(userId: string) {
  return axios.get(`/api/chemistryMeetings/${userId}`).then(({ data }) => data)
}

/**
 * Creates a Chemistry Meeting between the authenticated user and the userId
 * in the URL
 */
export async function createChemistryMeetingsUrl(userId: string) {
  return axios.post(`/api/chemistryMeetings/${userId}`).then((res) => res.data)
}
