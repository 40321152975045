import { TextButton } from '@components'
import { default as classNames, default as cn } from 'classnames'
import React from 'react'

type ExpertiseSidebarProps = {
  // Handles onClick events
  onClick: (values: string[]) => void
  /** List of objects representing expertise options */
  options: {
    /** Value which will be sent on the onChange callback */
    value: string
    /** Visual name of item */
    name?: string
  }[]
  /** List of values that are currently selected */
  selected: string[]
  className?: string
}

export function ExpertiseSidebar({ onClick, options, selected, className }: ExpertiseSidebarProps) {
  const toggleExpertise = (value: string) => {
    if (selected.includes(value)) {
      return onClick(selected.filter((_value) => _value !== value))
    } else {
      return onClick([...selected, value])
    }
  }

  return (
    <div
      className={cn('flex flex-col text-right items-end flex-shrink-0', className)}
      style={{ width: options.length ? 200 : 'auto' }}
    >
      {options.map(({ value, name = value }) => (
        <TextButton
          className={classNames('text-right', {
            'text-pink': selected.includes(value),
          })}
          style={{
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          key={value}
          onClick={() => toggleExpertise(value)}
        >
          {name}
        </TextButton>
      ))}
    </div>
  )
}
