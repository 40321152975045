import classNames from 'classnames'
import { forwardRef } from 'react'

export type OptionObject = {
  /** Value which will be sent on the onChange callback */
  value: string
  /** Visual name of item. Defaults to value */
  name?: string
}

type SelectProps = {
  /** Label to place on the left of the select */
  label?: string
  /**
   * Set the input as required and add an `*` to the label.
   * @default true
   * */
  required?: boolean
  /** Represents the selected value */
  selected?: string
  /** Accepts array of strings or an object of types */
  /** TODO: Possibly have a generic type with a getter */
  options: string[] | OptionObject[]
  onChange: (category: string) => void
  /** Custom className styles */
  className?: string
}

/** Labelled Select Input Component */
export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      label,
      required = true,
      // Defaulting to '' to make input always controlled
      selected = '',
      options: _options = [],
      onChange,
      className,
    },
    ref
  ) => {
    const optionType = typeof _options[0] === 'string' ? 'string' : 'object'
    const options =
      optionType === 'string'
        ? ((_options as string[]).map((value) => ({ value, name: value })) as OptionObject[])
        : (_options as OptionObject[])

    return (
      <label className={classNames('relative flex gap-2 items-center mt-2', className)}>
        {/* Add '*' to all labelled inputs that are required */}
        {label && `${label}${required ? '*' : ''}`}
        {/* Arrow */}
        <div
          className='rounded-full absolute right-2 align-middle z-10'
          style={{
            height: 16,
            width: 16,
            backgroundColor: '#C7327C59',
            top: 'calc(50% - 8px)',
            backgroundImage: "url('/arrow.svg')",
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '55% 50%',
          }}
        ></div>
        {/* Dropdown */}
        <select
          value={selected}
          ref={ref}
          required={required}
          name={label}
          className='flex-grow'
          onChange={(e) => onChange(e.currentTarget.value)}
        >
          <option disabled hidden value=''></option>
          {options.map(({ value, name }) => (
            <option value={value} key={value}>
              {name || value}
            </option>
          ))}
        </select>
      </label>
    )
  }
)
