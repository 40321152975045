import { useToggleState } from '@utils'
import classNames, { Argument } from 'classnames'
import { useEffect, useState } from 'react'

type TextAreaProps = {
  label?: string
  labelPosition?: 'TOP' | 'LEFT'
  required?: boolean
  value: string
  onChange: (value: string) => void
  className?: Argument
}

export function TextArea({ label, labelPosition = 'TOP', required, value, onChange, className }: TextAreaProps) {
  return (
    <label className={classNames('text-14 text-gray font-medium mt-0', className)}>
      {/* Add '*' to all labelled inputs that are required */}
      {label && `${label}${required ? '*' : ''}`}
      {labelPosition === 'TOP' ? (
        <div>
          <textarea
            className={classNames('rounded-2xl shadow-xl border-2 px-2 py-1 w-full', {
              'mt-1': label,
            })}
            style={{ minHeight: 100 }}
            required={required}
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
        </div>
      ) : (
        <textarea
          className='mt-1 rounded-2xl shadow-xl border p-2 w-full'
          style={{ minHeight: 100 }}
          required={required}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    </label>
  )
}

type TextFieldProps = {
  /** Render children when no in edit mode */
  children: JSX.Element
  /**
   * Disable edit-ability of the text
   * @deprecated Use `readOnly` instead
   */
  disabled?: boolean
  /**
   * Show form element as a readOnly value. User cannot change the value
   * @default false
   * */
  readOnly?: boolean
  /** Handler for when text field edits are complete */
  onChange: (value: string) => void
} & TextAreaProps

/** Renders a styles textarea with the ability to turn it into an editable field */
export function TextAreaField({
  children,
  disabled = false,
  readOnly = (disabled = false),
  onChange,
  ...textAreaProps
}: TextFieldProps) {
  // TODO: xState might be helpful here
  const [isEditMode, toggleEditMode] = useToggleState(false)
  // Holding local state since TextAreaField can have edited content and must be
  // able to render the original content
  const [inputValue, setInputValue] = useState(textAreaProps.value)

  // Update the textarea value to it's original value when going into edit mode
  useEffect(() => {
    if (isEditMode === true) {
      setInputValue(textAreaProps.value)
    }
  }, [isEditMode])

  useEffect(() => {
    // Reset the edit state when new values are given
    toggleEditMode(false)
  }, [textAreaProps.value])

  return readOnly ? (
    children
  ) : (
    <>
      {/* Only render children when disabled */}
      {!isEditMode ? (
        <div className='flex gap-1 items-center'>
          {children}
          <img
            src='/pencil.svg'
            alt='pencil'
            height='13px'
            width='13px'
            className='cursor-pointer'
            onClick={() => toggleEditMode()}
          />
        </div>
      ) : (
        <div className='flex gap-1 items-end'>
          <TextArea {...textAreaProps} value={inputValue} onChange={setInputValue} className='flex-grow' />
          <div className='flex gap-1 items-center'>
            <img
              src='/check.svg'
              alt='check'
              height='20px'
              width='20px'
              className='cursor-pointer'
              onClick={() => onChange(inputValue)}
            />
            <img
              src='/cancel.svg'
              alt='cancel'
              height='17px'
              width='17px'
              className='cursor-pointer'
              onClick={() => toggleEditMode()}
            />
          </div>
        </div>
      )}
    </>
  )
}
