/** Client Utilities */
export * from './contentful.utils'
export * from './queries'
export * from './useForm'
export * from './useToggleState'

// Constants
export const useType = () => ['Consultation', 'Peer Support']

export const offerings = ['Consultation' as const, 'Peer Support' as const]
