import { Header, Sidebar } from '@components'
import React, { ReactNode } from 'react'

type ChildrenObject = {
  left: ReactNode
  right: ReactNode
}

type LayoutProps = {
  children: ReactNode | ChildrenObject
}

export function Layout({ children }: LayoutProps) {
  return (
    <div className='h-screen flex flex-col'>
      <Header />
      {/* h-0 hack to give the container a set height which is ignored */}
      <div className='h-0 flex flex-grow'>
        <Sidebar />
        <main className='px-10 flex flex-grow gap-8 overflow-auto'>
          {!isChildrenObject(children) ? (
            <div className='w-full' style={{ maxWidth: 700 }}>
              {children}
            </div>
          ) : (
            <>
              <div className='w-full flex-shrink-0' style={{ maxWidth: 700 }}>
                {(children as ChildrenObject).left}
              </div>
              <div className='flex-grow'>{(children as ChildrenObject).right}</div>
            </>
          )}
        </main>
      </div>
    </div>
  )
}

function isChildrenObject(children: LayoutProps['children']) {
  return 'left' in (children as Object)
}
