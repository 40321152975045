import { isEqual } from 'lodash'
import { useEffect, useRef, useState } from 'react'

type Form<FormType> = {
  /** Value of the form */
  value: FormType
  /** Change the form.value and trigger onChange. */
  set: (newFormValue: FormType) => void
  /** Represents if all form fields are valid */
  valid: boolean
  /** Represents if the form in readOnly mode */
  readOnly: boolean
  /** Reset the form to the initValue */
  reset: () => void
  /**
   * Emits when form.field.set is called.
   * Used to trigger mutations when form changes.
   */
  onChange: (form: Partial<FormType>) => void
} & {
  [key in keyof FormType]?: {
    /** Change the form[key] value and triggers onChange. */
    set: (newValue: FormType[key]) => void
    /** Returns the current value of the form[key] */
    value: FormType[key]
  }
}

type FormOptions<FormType> = {
  /**
   * Enables readOnly mode for form which will affect form.readOnly and will
   * not allow any form.field.set actions.
   * @default false
   */
  readOnly?: boolean
  /** Handler triggered by form value changes */
  onChange?: (form: Partial<FormType>) => void
}

/**
 * TODO: Support required fields
 * TODO: Create proxy for when fields are not given from initialValue and to set
 * values when not given
 * TODO: Try to make TextField to use debounce to limit the amount of hits to formState?
 * Can this even be done since this IS the state for this component.
 */
export function useForm<FormType>(_initValue: Partial<FormType>, options?: FormOptions<FormType>) {
  // Since we cannot guarantee that the initValue will be memoized, we must keep
  // track of the original value for comparison with any new _initValue.
  const { current: initValue } = useRef(_initValue)

  const [formState, setFormState] = useState(initValue)

  const { readOnly = false, onChange } = options ?? {}

  const tickRef = useRef(0) // Used to track hook renders
  const tick = tickRef.current

  // When _initValue changes, reset the formState as this takes priority
  useEffect(() => {
    // Only trigger after initial render
    if (tick && !isEqual(initValue, _initValue)) {
      setForm(initValue)
      // Reset tick to not trigger an onChange
      tickRef.current = 0
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValue])

  /**
   * Triggers onChange when formState changes. setFormState is controlled by
   * setForm which checks for prev and new value diffs.
   */
  useEffect(() => {
    // Only trigger onChange after initial render
    if (tick && onChange) onChange(formState)

    tickRef.current++
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState])

  /** Updates formState only when resulting state is different */
  function setForm(_newFormValues: Partial<FormType>) {
    const _formState = { ...formState, ..._newFormValues }
    if (!isEqual(formState, _formState)) setFormState(_formState)
  }

  const formHelpers = {
    value: formState,
    set: setForm,
    // TODO: Improve this
    get valid() {
      // Check each key in the formState and make sure they are not undefined
      // nor empty
      return Object.keys(formState).every((key) => {
        // Handle arrays
        if (Array.isArray(formState[key])) {
          return formState[key].length > 0
        }
        // Handle objects and values
        // TODO: Check for objects
        // TODO: This is not going to handle falsy values
        return !!formState[key] && formState[key].length > 0
      })
    },
    // Skipping setForm since partials are not allowed in setForm
    reset: () => setFormState(initValue),
    readOnly,
  }

  return Object.keys(formState).reduce((acc, key) => {
    return {
      ...acc,
      [key]: {
        // Using `any` here but types are properly set in Form type
        set: (newValue: any) => {
          // Bail when in readOnly mode
          if (readOnly) {
            console.warn(`Cannot call form.${key}.set when in readOnly mode.`)
            return
          }

          setForm({
            [key]: newValue,
          } as Partial<FormType>)
        },
        value: formState[key],
      },
    }
  }, formHelpers as Form<FormType>)
}
