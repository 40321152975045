import cn from "classnames";

/** Controlled checkbox component */
export function Checkbox({
  selected,
  value,
  name = value,
  onChange,
}: {
  selected: boolean;
  value: string;
  name?: string;
  onChange: (isSelected: boolean) => void;
}) {
  return (
    <div
      className={cn("flex gap-1 text-14 cursor-pointer", {
        "text-pink": selected,
      })}
      onClick={() => onChange(!selected)}
    >
      {/* Checkbox */}
      {selected ? (
        <svg
          width="20"
          height="21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="2" y="2" width="16" height="16" rx="3" fill="#C7327C" />
          <path d="M6 10.5l3.13636 3L14.5 7" stroke="#fff" />
        </svg>
      ) : (
        <svg
          width="20"
          height="21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d)">
            <rect
              x="2.5"
              y="2.5"
              width="15"
              height="15"
              rx="2.5"
              stroke="#E6E6E6"
            />
          </g>
          <defs>
            <filter
              id="filter0_d"
              x="0"
              y=".5"
              width="20"
              height="20"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dy=".5" />
              <feGaussianBlur stdDeviation="1" />
              <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
              <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
              <feBlend
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      )}
      {name}
    </div>
  );
}
