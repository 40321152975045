import { IconButton } from '@components'
import { User } from '@prisma/client'
import Link from 'next/link'
import { useState } from 'react'

export type ProfileProps = {
  user: User
  onAccept?: (userId: string) => void
  onDecline?: (userId: string) => void
  /** When enabled, show the "view profile" button */
  showProfileLink?: boolean
  onConnect?: (id: User['id']) => void
  /** True if the current user has requested a connection with this user */
  pendingConnection?: boolean
}

export function Profile({
  user,
  onAccept,
  onDecline,
  showProfileLink,
  onConnect,
  pendingConnection = false,
}: ProfileProps) {
  const [showImage, setShowImage] = useState(true)

  return (
    <div className='flex gap-2 font-poppins text-12 text-pureBlack relative'>
      {/* Line */}
      <div className='absolute right-0 left-0 bg-grayLine' style={{ height: 1, zIndex: -1, bottom: 10 }}></div>
      {/* Photo */}
      {showImage && (
        <img
          src={user.picture}
          onError={() => setShowImage(false)}
          alt='connection'
          className='ml-3 object-contain rounded-full'
          style={{
            maxHeight: 68,
            maxWidth: 68,
            height: '100%',
            width: '100%',
          }}
        />
      )}
      {/* Information */}
      <div className='flex flex-col justify-center pb-2 flex-grow'>
        <div className='font-medium'>{`${user.firstName} ${user.lastName}`}</div>
        {/* TODO: Missing position */}
        {/* <div>Innovation Manager | City of Fredericton</div> */}
        {user.companyName && <div>{user.companyName}</div>}
      </div>
      <div className='flex gap-1 items-end'>
        {/* Accept */}
        {/* TODO: Add highlight state */}
        {onAccept && (
          <IconButton style={{ padding: 0, boxShadow: 'none' }} onClick={() => onAccept(user.id)}>
            <img src='/accept.svg' alt='accept' width='24px' height='24px' />
          </IconButton>
        )}
        {/* Decline */}
        {onDecline && (
          <IconButton style={{ padding: 0, boxShadow: 'none' }} onClick={() => onDecline(user.id)}>
            <img src='/decline.svg' alt='decline' width='24px' height='24px' />
          </IconButton>
        )}
      </div>
      <div className='flex gap-1 items-end flex-col justify-end' style={{ width: 75 }}>
        {showProfileLink && (
          <Link href={`/profiles/${user.id}`}>
            <a
              className='border border-pink bg-pink rounded-full px-1 py-0.5 text-white font-poppins font-medium mb-auto'
              style={{
                fontSize: 9,
                lineHeight: '12px',
                width: '100%',
                textAlign: 'center',
              }}
            >
              View Profile
            </a>
          </Link>
        )}
        {/* onConnect */}
        {onConnect && !pendingConnection && (
          <button
            className='text-10 bg-blue w-full'
            style={{ paddingTop: 4, paddingBottom: 4, fontSize: 9 }}
            onClick={() => onConnect(user.id)}
          >
            Connect
          </button>
        )}
        {/* TODO: Support toggling pendingConnections */}
        {pendingConnection && (
          <button
            className='text-10 cursor-not-allowed w-full'
            style={{ paddingTop: 4, paddingBottom: 4, fontSize: 9 }}
          >
            Pending
          </button>
        )}
      </div>
    </div>
  )
}
